<template>
  <div>
    <div>
      <img src="../../assets/new-bg.png" alt="" height="680px" width="100%">
    </div>
    <div class="news">

      <div class="news-left">
        <div class="news-list">
          <div
              :class="index==0?'big-news':'news-item'"
              v-for="(item,index) in newsList"
              :key="index"
              @click="clickNewsItem(item.id)"
          >
            <img  :src="item.coverPicture" alt="">
            <div>{{item.title}}</div>
            <div>{{item.releaseTime}}</div>
          </div>
        </div>
        <el-pagination
            class="myPagination"
            background
            :current-page="pageNum"
            :page-size="pageSize"
            :total="total"
            @current-change="currentChange"
        >
        </el-pagination>
      </div>
      <div class="news-right">
        <div class="btn-item"
             v-for="(item,index) in typeList"
             :key="index"
             :class="typeIndex==item.dictValue?'active':''"
             @click="typeIndex=item.dictValue;getNewList();"
        >
          {{item.dictLabel}}
        </div>
        <div class="btn-item"
             @click="typeIndex='';getNewList();"
             :class="typeIndex==''?'active':''"

        >全部</div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "news",
  data(){
    return {
      pageNum:1,
      pageSize:5,
      total:0,
      newsList:[],
      typeList:[],
      typeIndex:'',
    }
  },
  created() {
    this.getNewList();
    this.getTypeList();
  },
  methods:{
    submit(){
      console.log(this.content);
    },
    getNewList(){
      this.$get(`/website/news/list?orderByColumn=release_time&isAsc=desc&pageNum=${this.pageNum}&pageSize=${this.pageSize}&tagCode=${this.typeIndex}`).then((res)=>{
        this.newsList=res.rows;
        this.total=res.total;
      }).catch((err)=>{
        this.msgError(err.msg);
      })
    },
    currentChange(val){
      this.pageNum=val;
      this.getNewList();
    },
    getTypeList(){
      this.$get('/website/news/getNewsTagsType').then((res)=>{
          this.typeList=res.rows;
      }).catch((err)=>{
        this.msgError(err.msg);
      })
    },
    clickNewsItem(id){
      this.$router.push({path:'/newsDetail',query: {id:id}})
    }

  }
}
</script>

<style scoped lang="scss">
.news{
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  .news-left{
    width: 800px;
    .news-list{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      .news-item{
        width: 380px;
        margin-top: 20px;
        cursor: pointer;
        img{
          width: 100%;
          height: 200px;
        }
      }
      .big-news{
        width: 800px;
        img{
          width: 100%;
          height: 400px;
        }
      }
      .news-item:nth-of-type(odd){
        margin-left: 40px;
      }
    }
  }
  .news-right{
    width: 280px;
    .btn-item{
      cursor: pointer;
      margin-right: 20px;
      display: inline-block;
      padding: 5px 10px;
      background: #F2F2F2;
      margin-bottom: 20px;
      border-radius: 5px;
    }
    .btn-item.active{
      background: #409EFF;
      color: #fff;
    }
  }
}
.myPagination{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
